import { environment } from './../environments/environment';
import { Component, OnInit } from '@angular/core';
import { getUserInfo } from '@igps/client-root-config'
import { UserInfo } from './models/userInfo';
import { Router } from '@angular/router';
@Component({
  selector: 'client-navigation',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'NavigationBar-UI';
  userInfo!: UserInfo;
  constructor(private router: Router) {

  }
  ngOnInit(): void {
    this.userInfo = getUserInfo();
  }


  redirectToDashBoard() {
    if (this.userInfo.assumedRoleId === 1) {
      this.router.navigate(['/manager/dashboard']);
    }
    else {
      this.router.navigate(['/processor/dashboard']);
    }
  }

  navigateToAdmin() {
    window.open(environment.adminURL, 'igps-admin')?.focus();
  }

}
